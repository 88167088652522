
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets= [
    'movementId'
  ]
  connect() {
    console.log('conecto con external')
  }

  setDate(){
    console.log(this.movementIdTarget.value)
    let start_date = document.querySelector('#start_externalize_'+this.movementIdTarget.value).value
    if (start_date == ''){
      console.log('setea la fecha')
      document.querySelector('#start_externalize_'+this.movementIdTarget.value).value = Date().slice(0, -22)
    }
  }
}