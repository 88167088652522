import { Controller } from "@hotwired/stimulus";
import { validateRut } from 'rutlib';

export default class extends Controller {
  static targets= [
    'category',
    'validateRutPatient',
    'patientCall'
  ]
  connect() {
    this.cascadeOnChangeEvent();
    this.setCategoryField();
  }

  updateCategoryColor({ value = null }){
    let color = document.getElementById('category-color');
    let hiddenField = document.getElementById("category_hidden_field");
    let category = value || this.categoryTarget.value;
    if (category.includes('R1')) {
        color.style.cssText = 'color:#E30001 !important';
        hiddenField.value = "R1";
    } else if (category.includes('R2')) {
        color.style.cssText = 'color:#FAAD14 !important';
        hiddenField.value = "R2";
    } else if (category.includes('R3')) {
        color.style.cssText = 'color:#E6EB03 !important';
        hiddenField.value = "R3";
    } else if (category.includes('R4')) {
        color.style.cssText = 'color:#30BA00 !important';
        hiddenField.value = "R4";
    } else {
        hiddenField.value = "";
        color.style.cssText = 'color:#D9D9D9 !important';
    }
  }

  cascadeOnChangeEvent() {
    window.addEventListener('changed_input_in_cascader', (event) => {
      let cascaderComponent_id = document.getElementById("cascaderComponent_id")
      document.getElementById("category_detail_hidden_field").value = cascaderComponent_id.value
      let value = event.detail.value;
      this.updateCategoryColor({ value: value });
    });
  }

  setCategoryField() {
    document.getElementById("cascaderComponent_id").value = document.getElementById("category_detail_hidden_field").value
  }

  validatePhone(){
    const reg = new RegExp('[a-zA-Z--.?/"]');
    let phone = document.getElementById("phone").value
    if (phone[0] != '+'){
      if (phone.length == 9){
        document.getElementById("alert-phone").classList.add('d-none')
      }
      else{
        document.getElementById("alert-phone").classList.remove('d-none')
      }
    }
    if ((reg.test(phone))){
      document.getElementById("alert-phone").classList.remove('d-none')
    }
  }

  validateRutPatient() {
    let patientRut = document.getElementById('patient_rut').value
    this.clearPatientField()
    if (validateRut(patientRut)){
      console.log('entro a remover el disable')
      document.getElementById('alert_patient_rut').classList.add('d-none')
      document.getElementById('checkPatientBtn').classList.remove('disabled')
    }
    else {
      document.getElementById('alert_patient_rut').classList.remove('d-none')
      document.getElementById('checkPatientBtn').classList.add('disabled')
    }
  }
  validateRutEnterprise() {
    let enterpriseRut = document.getElementById('enterprise_rut').value
    this.clearEnterpriseField()
    if (validateRut(enterpriseRut)){
      document.getElementById('alert_enterprise_rut').classList.add('d-none')
      document.getElementById('checkEnterpriseBtn').classList.remove('disabled')
    }
    else {
      document.getElementById('alert_enterprise_rut').classList.remove('d-none')
      document.getElementById('checkEnterpriseBtn').classList.add('disabled')
    }
  }

  clearPatientField(){
    document.getElementById('checkIconPatient').classList.add('d-none')
    document.getElementById('xmarkIconPatient').classList.add('d-none')
    document.getElementById('success_patient_afiliation').classList.add('d-none')
    document.getElementById('alert_patient_afiliation').classList.add('d-none')
    document.getElementById('patient_rut').style.cssText = 'border-color: none';
  }

  clearEnterpriseField(){
    document.getElementById('checkIconEnterprise').classList.add('d-none')
    document.getElementById('xmarkIconEnterprise').classList.add('d-none')
    document.getElementById('success_enterprise_afiliation').classList.add('d-none')
    document.getElementById('alert_enterprise_afiliation').classList.add('d-none')
    document.getElementById('enterprise_rut').style.cssText = 'border-color: none';
  }

  ValidateAfiliationPatient(){
    this.loaderPatient()
    let rut = document.getElementById('patient_rut').value
    let body = { "rut": rut}
    fetch(`/api/v1/affiliation/validate_patient_afiliation?rut=${rut}&type=datos_estandar_rescates_afiliado`, {method: 'POST', body: body})
    .then(data => {
      if(data.status == 200) {
        document.getElementById('waiting_text_patient').classList.add('d-none')
        document.getElementById('checkIconPatient').classList.remove('d-none')
        document.getElementById('success_patient_afiliation').classList.remove('d-none')
        document.getElementById('patient_rut').style.cssText = 'border-color: #30BA00';
        document.getElementById('textValidatePatient').classList.remove('loader')
        document.getElementById('patient_afiliation_hidden_field').value = true
      }
      else {
        document.getElementById('waiting_text_patient').classList.add('d-none')
        document.getElementById('xmarkIconPatient').classList.remove('d-none')
        document.getElementById('alert_patient_afiliation').classList.remove('d-none')
        document.getElementById('patient_rut').style.cssText = 'border-color: #E30001';
        document.getElementById('textValidatePatient').classList.remove('loader')
        document.getElementById('patient_afiliation_hidden_field').value = ''
      }
    })
  }

  ValidateAfiliationEnterprise(){
    this.loaderEnterprise()
    let rut = document.getElementById('enterprise_rut').value
    let body = { "rut": rut }
    fetch(`/api/v1/affiliation/validate_patient_afiliation?rut=${rut}&type=datos_estandar_rescates_empresa`, {method: 'POST', body: body})
    .then(data => {
      if(data.status == 200) {
        console.log(data)
        document.getElementById('waiting_text_enterprise').classList.add('d-none')
        document.getElementById('checkIconEnterprise').classList.remove('d-none')
        document.getElementById('success_enterprise_afiliation').classList.remove('d-none')
        document.getElementById('enterprise_rut').style.cssText = 'border-color: #30BA00';
        document.getElementById('textValidateEnterprise').classList.remove('loader')
        document.getElementById('enterprise_afiliation_hidden_field').value = true
        data.json().then(json => {
          document.getElementById('social_reason').value = json[0][1]
        })
      }
      else {
        document.getElementById('waiting_text_enterprise').classList.add('d-none')
        document.getElementById('xmarkIconEnterprise').classList.remove('d-none')
        document.getElementById('enterprise_rut').style.cssText = 'border-color: #E30001';
        document.getElementById('alert_enterprise_afiliation').classList.remove('d-none')
        document.getElementById('textValidateEnterprise').classList.remove('loader')
        document.getElementById('enterprise_afiliation_hidden_field').value = ''
      }
    })
  }

  loaderEnterprise() {
    document.getElementById('alert_enterprise_afiliation').classList.add('d-none')
    document.getElementById('textValidateEnterprise').classList.add('loader')
    document.getElementById('waiting_text_enterprise').classList.remove('d-none')
  }

  loaderPatient() {
    document.getElementById('alert_patient_afiliation').classList.add('d-none')
    document.getElementById('textValidatePatient').classList.add('loader')
    document.getElementById('waiting_text_patient').classList.remove('d-none')
  }

  patientCall() {
    document.getElementById('contact_name_field').disabled = this.patientCallTarget.checked
    document.getElementById('contact_phone_field').disabled = this.patientCallTarget.checked
    document.getElementById('contact_position_field').disabled = this.patientCallTarget.checked

    if (this.patientCallTarget.checked) {
      document.getElementById('contact_name_field').value = ''
      document.getElementById('contact_phone_field').value = ''
      document.getElementById('contact_position_field').value = ''
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}