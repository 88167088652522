import consumer from "./consumer"
import L from 'leaflet';


consumer.subscriptions.create("VehiclePositionChannel", {
  connected() {
    console.log("conectado al canal de posicion")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
		console.log('data trae', data)
		if(window.location.pathname == '/movements/monitor'){
			this.updatePosition(data);
		}
    // Called when there's incoming data on the websocket for this channel
    
  },

  updatePosition(data){
		console.log(data)
		let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-van-shuttle" id="vehicleMarker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorCoordinatesVehicleValue = `{"latitude_${data.vehicle_id}": "${data.latitude}" , "longitude_${data.vehicle_id}": "${data.longitude}"}`
    let prevMap = window[`mapGoogle${data.vehicle_id}`]
		let latitude = document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorLatitudeIdValue
		let longitude = document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorLongitudeIdValue
		let cLatitude = document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorLatitudeCidValue
		let cLongitude = document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorLongitudeCidValue
    if(prevMap){
			var newLatLng = new L.LatLng(data.latitude, data.longitude);
			window[`vehicleMarker${data.vehicle_id}`].setIcon(generatedIcon)
			window[`vehicleMarker${data.vehicle_id}`].setLatLng(newLatLng); 
			window[`mapGoogle${data.vehicle_id}`].removeLayer(window[`polyline${data.vehicle_id}`])
			if(document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorStateValue == 'on_rescue_route' ||
				document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorStateValue == 'on_rescue' ||
				document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorStateValue == 'pending_derivation'||
				document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorStateValue == 'assigned' ||
				document.getElementsByName(`vehicle_${data.vehicle_id}`)[0].dataset.monitorStateValue == 'pre_assigned'){
				window[`polyline${data.vehicle_id}`] = L.polyline([[latitude, longitude],[data.latitude, data.longitude]], {
					color: '#A66FFF',
					weight: 3,
					opacity: 1,
					smoothFactor: 1
					}).addTo(prevMap); 
			}
			else{
				console.log('entro sin los estados')
				window[`polyline${data.vehicle_id}`] = L.polyline([[cLatitude, cLongitude],[data.latitude, data.longitude]], {
					color: '#A66FFF',
					weight: 3,
					opacity: 1,
					smoothFactor: 1
					}).addTo(prevMap); 
			}
    }
  }
});
