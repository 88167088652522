import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'movement_id'
  ]

  connect() {
    console.log('conecto', this)
    console.log(this.movement_idTarget.value)
    this.cascadeOnChangeEvent()
  }

  setCategoryField() {
    document.getElementById("cascaderComponent_id"+this.movement_idTarget.value).value = document.getElementById("category_detail_hidden_field"+this.movement_idTarget.value).value
  }
  
  cascadeOnChangeEvent() {
    window.addEventListener('changed_input_in_cascader', (event) => {
      let cascaderComponent_id = document.getElementById("cascaderComponent_id"+this.movement_idTarget.value)
      document.getElementById("category_detail_hidden_field"+this.movement_idTarget.value).value = cascaderComponent_id.value
      let value = event.detail.value;
      let id = event.detail.movement_id
      this.updateCategoryColor({ value: value, id: id });
    });
  }

  updateCategoryColor({ value = null, id = null }){
    let color = document.getElementById('category-color'+id);
    let hiddenField = document.getElementById("category_hidden_field"+id);
    if(value != null){
      document.getElementById('send_btn'+id).classList.remove('disabled')
    } else {
      document.getElementById('send_btn'+id).classList.add('disabled')
    }
    let category = value || this.categoryTarget?.value;
    if (category?.includes('R1')) {
        color.style.cssText = 'color:#E30001 !important';
        hiddenField.value = "R1";
    } else if (category?.includes('R2')) {
        color.style.cssText = 'color:#FAAD14 !important';
        hiddenField.value = "R2";
    } else if (category?.includes('R3')) {
        color.style.cssText = 'color:#E6EB03 !important';
        hiddenField.value = "R3";
    } else if (category?.includes('R4')) {
        color.style.cssText = 'color:#30BA00 !important';
        hiddenField.value = "R4";
    } else {
        hiddenField.value = "";
        color.style.cssText = 'color:#D9D9D9 !important';
    }
  }

  setScroll(){
    console.log(document.getElementById('recategorization-form'))
    document.getElementById('recategorization-form').scrollTop = 2130
    document.getElementById('movement_observations_second_category').scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    })
    console.log(document.getElementById('recategorization-form').scrollTop)
  }
}