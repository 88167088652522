import { Controller } from "@hotwired/stimulus";
import 'leaflet-control-geocoder';
import L from 'leaflet';

export default class extends Controller {
  connect() {
    this.sleep(50).then(() => {
      this.setMarker()
    })
  }

  setMarker() {
    for (var i = 0; i < this.element.parentElement.children.length; i++){
      var child = this.element.parentElement.children[i]
      child.style.cssText = 'background-color: none'
    }
    let latLon = this.element.children[0].textContent.trim().split(',')
    let data = this.element.children[1].textContent

    var myLatLng = new google.maps.LatLng(latLon[0],latLon[1])
    var mapOptions = {
      zoom: 15,
      center: myLatLng
    }
    var icon = {
      path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "#BE50FF",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(0, 20)
    }
    var map = new google.maps.Map(document.getElementById('mapGoogle'), mapOptions)
    var marker = new google.maps.Marker({
      position: myLatLng,
      title: data,
      icon: icon
    })

    this.element.style.cssText = 'background-color: #F4EEFF'
    marker.setMap(map)
  }

  addMarker() {
    let latLon = this.element.children[0].textContent.trim().split(',')
    var map = document.getElementById('mapGoogle')
     L.tileLayer(this.urlValue, {
      attribution: '<a href="https://www.here.com/">Here</a>',
    }).addTo(map)
    console.log(map)
    var marker = L.marker(latLon).addTo(map)
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
