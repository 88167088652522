import { Controller } from "@hotwired/stimulus";
import L from 'leaflet';
import 'leaflet-control-geocoder';

export default class extends Controller {
  static values = {
    url: String,
    type: String,
    addressId: String,
    latitudeId: String,
    longitudeId: String,
    markerTitle: String,
    apiKey: String,
  }

  map = null;
  markers = [];
  geocoder = null

  connect() {
    this.initGeocoderProvider();
    this.initializeMap();
    this.searhbBoxChangedEvent();
    this.geolocatorChangedEvent();
    this.mapClickEvent();
  }

  addMarkerTo(icon,title,latLng,draggable) {
    let marker = L.marker(latLng, {
      title: title,
      draggable: draggable,
      icon: icon
    }).addTo(this.map);
    this.markerDragendEvent(marker);
    return marker;
  }

  initializeMap() {
    let latitude_element = document.getElementById(this.latitudeIdValue) || null;
    let longitude_element = document.getElementById(this.longitudeIdValue) || null;
    let draggable_element = document.getElementById("draggable") || null;

    let latitude = (latitude_element !== null && latitude_element.value != "") ? latitude_element.value : -33.437853;
    let longitude = (longitude_element !== null && longitude_element.value != "") ? longitude_element.value : -70.650458;
    let draggable = (draggable_element !== null && draggable_element.value != "") ? (draggable_element.value === "true" ? true : false) : true;

    this.map = new L.Map(this.element,{
      minZoom: 1,
    });
    let tileLayer;
    if (this.typeValue === "google") {
      tileLayer = this.setGoogleMapTileLayer();
    } else if (this.typeValue === "here") {
      tileLayer = this.setHereMapTileLayer();
    }
    this.map.setView(new L.LatLng(latitude, longitude), 15);
    tileLayer.addTo(this.map);

    if ((latitude_element !== null && longitude_element !== null) && (latitude_element.value != "" && longitude_element.value != "")) {
      let marker = this.addMarkerTo(this.generatedIcon(), this.markerTitleValue, [latitude, longitude], false);
      this.markers.push(marker);
    }
  }

  geolocatorChangedEvent() {
    let geolacator = document.getElementById('geolocator-botton');
    if (geolacator == null) return;
    geolacator.addEventListener('changed_location_map', (event) => {
      this.addMarkerChangedEvent(event);
    });
  }

  searhbBoxChangedEvent() {
    let searchBox = document.getElementById('search-box');
    if (searchBox == null) return;
    searchBox.addEventListener('changed_location_map', (event) => {
      this.addMarkerChangedEvent(event);
    });
  }

  addMarkerChangedEvent(event) {
    let place = event.detail.place;

    this.removeMarkers()

    let bounds = L.latLngBounds();

    if (!place.geometry || !place.geometry.location) {
      console.log("Returned place contains no geometry");
      return;
    }
    let latLng = [place.geometry.location.lat(), place.geometry.location.lng()];
    let marker = this.addMarkerTo(this.generatedIcon(), this.markerTitleValue, latLng, true);
    this.markers.push(marker);
    if (place.geometry.viewport) {
      bounds.extend([place.geometry.viewport.getNorthEast().lat(), place.geometry.viewport.getNorthEast().lng()]);
      bounds.extend([place.geometry.viewport.getSouthWest().lat(), place.geometry.viewport.getSouthWest().lng()]);
    } else {
      bounds.extend([place.geometry.location.lat(), place.geometry.location.lng()]);
    }
    
    this.map.fitBounds(bounds);
  }

  mapClickEvent() {
    let self = this;
    this.map.on('click', function(event) {

        let latLng = event.latlng
        self.geocoder.reverse(latLng, self.map.options.crs.scale(self.map.getZoom()), function(results) {
          if (results.length === 0) return;
          let place = results[0];

          self.removeMarkers();

          if (!place.center) {
              console.log("Returned place contains no waypoint");
              return;
          }

          let markerLatLng = [place.center.lat, place.center.lng];
          let marker = self.addMarkerTo(self.generatedIcon(), self.markerTitleValue, markerLatLng, true);
          self.markers.push(marker);
          self.updateAddressFields(place);
        });
    });
  }

  markerDragendEvent(marker) {
    self = this;
    marker.on('dragend', function(event) {
      // Obtain latLong of map.event
      let latLng = event.target.getLatLng();
      self.geocoder.reverse(latLng, self.map.options.crs.scale(self.map.getZoom()), function(results) {
        if (results.length === 0) return;
        let place = results[0];
  
        if (!place.center) {
          console.log("Returned place contains no waypoint");
          return;
        }
        
        self.updateAddressFields(place);
      });
    });
  }

  setGoogleMapTileLayer() {
    let tileLayerUrl = this.urlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '<a href="https://www.google.com/maps">Google</a>',
    })
    return tileLayer;
  }

  setHereMapTileLayer() {
    let tileLayerUrl = this.urlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      attribution: '<a href="https://www.here.com/">Here</a>',
    })
    return tileLayer;
  }

  updateAddressFields(place){
    let addressInput = document.getElementById(this.addressIdValue);
    if (addressInput != null){
      addressInput.value = place.name
    }
    let latitudeInput = document.getElementById(this.latitudeIdValue);
    if (latitudeInput != null){
      latitudeInput.value = place.center.lat
    }
    let longitudeInput = document.getElementById(this.longitudeIdValue);
    if (longitudeInput != null){
      longitudeInput.value = place.center.lng
    }

    self.setAllAddress(place.name.split(", "), false, undefined);
  }

  generatedIcon(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-map-marker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

  removeMarkers() {
    this.markers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
  }

  initGeocoderProvider() {
    if (this.typeValue === "google") {
      this.geocoder = L.Control.Geocoder.google({
        apiKey: this.apiKeyValue, 
      });
    } else if (this.typeValue === "here") {
      this.geocoder = L.Control.Geocoder.here({
        apiKey: this.apiKeyValue, 
        maxResults: 2,
        reverseGeocodeProxRadius: 2
      });
    }
  }

  setAllAddress(addr, withAddressComp, addressComponents) {
    let place_length = Object.keys(addr).length;
    const reversedKeys = Object.keys(addr).reverse();
    let index = 0;
    for (const key of reversedKeys) {
      const value = addr[key];
      if (withAddressComp) {
        if (value.long_name == "Chile" &&  value.short_name == "CL") {
          index = key;
          break;
        }
      } else {
        if (value == "Chile") {
          index = key;
          break;
        }
      }
    }

    if (index == 0) {
      return;
    }
    let idx = index-1;
    let region="";
    let country="";
    let commune="";
    if (withAddressComp) {
      region = addr[idx].long_name
      country = addr[index].long_name
      commune = addr[index-2].long_name
    }else{
      region = addr[idx]
      country = addr[index]
      commune = addr[index-2]
    }
    if (typeof region === "string" && region.length === 0) {
      return;
    }

    if (addressComponents!== undefined) {
      let addrCompLast = addressComponents[addressComponents.length - 1];
      commune = commune.replace(addrCompLast.long_name+" ", "");
    }

    let commune_aux = commune.split(" ");
    commune_aux.shift();
    commune = commune_aux.join(" ");

    const regions_arr = [
      {id: 1,name: 'Tarapacá'},
      {id: 2,name: 'Antofagasta'},
      {id: 3,name: 'Atacama'},
      {id: 4,name: 'Coquimbo'},
      {id: 5,name: 'Valparaíso'},
      {id: 6,name: "Libertador Gral. Bernardo O'Higgins"},
      {id: 7,name: 'Maule'},
      {id: 8,name: 'Bío Bío'},
      {id: 9,name: 'Araucanía'},
      {id: 10,name: 'Los Lagos'},
      {id: 11,name: 'Aisén del Gral. C. Ibáñez del Campo'},
      {id: 12,name: 'Magallanes y La Antártica Chilena'},
      {id: 13,name: 'Región Metropolitana de Santiago'},
      {id: 14,name: 'Los Ríos'},
      {id: 15,name: 'Arica y Parinacota'},
      {id: 16,name: 'Ñuble'},
    ]

    const result = regions_arr.find(obj => obj.name === region);
    if (result === undefined) {
      return;
    }
    let region_id= result.id;
    //region_id

    let regionIdInput = document.getElementById("region_id");
    if (regionIdInput != null){
      regionIdInput.value = region_id;
    }

    let communeInput = document.getElementById("commune");
    if (communeInput != null) {
      communeInput.value = commune;
    }
    console.log("******", {region_id,country, commune});
  }
}
