import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('conecto', this);
    new MultiSelectTag('regionfilter',{
      rounded: false,
      placeholder: 'Buscar',
      tagColor: {
        textColor: '#327b2c',
        borderColor: '#92e681',
        bgColor: '#eaffe6',
      }
    });
  }
}