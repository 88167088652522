import consumer from "./consumer"

consumer.subscriptions.create("RoleNotificationsChannel", {
  connected() {
    console.log("conectado al canal de notificacion por rol particular")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
		console.log('data trae', data)
    console.log('norificationes por rol')
    this.displayRoleNotification(data)
    // Called when there's incoming data on the websocket for this channel
  },

  displayRoleNotification(data){
    let user_regions = regions.split(',')
    if (user_regions.includes('all') || user_regions.includes(data.region_id.toString()) ) {
      if (data.roles.includes(role)){
        if (!("Notification" in window)) {
          console.warn("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
          console.log("notification granted nuevo");
          var notification = new Notification(data.title, { body: data.body });
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function(permission){
            if (permission === "granted"){
              new Notification("Hi there")
            }
          });
        } else {
          console.warn("notification denied");
        }
      }
    }
  }

});
