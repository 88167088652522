import { Controller } from "@hotwired/stimulus";
import readXlsxFile, { readSheetNames } from 'read-excel-file'

export default class extends Controller {
  static targets = ['selector']

  initialize() {
    this.payload = null
  }

  connect() {
   
  }
  
  prepare() {
    this.element.classList.remove('error')
  }

  loader() {
    console.log('entro al loader')
    document.getElementById('submit-text').classList.add('loader')
  }

  fileChosenmovement() {
    this.prepare()
    const file = this.selectorTarget.files[0]
    this.processRowsCenter(file) 
  }

  fileChosenexternal(){
    console.log('validacion external')
    this.prepare()
    const file = this.selectorTarget.files[0]
    this.processRowsExternal(file)
  }

  processRowsExternal(file){
    document.getElementById('error-external').classList.add('d-none')
    document.getElementById('check-external').classList.add('d-none')
    this.element.classList.add('verifying')
    readSheetNames(file).then((sheetNames) => {
      readXlsxFile(file).then((value) => {
        const page_1_names = ['Caso', 'Asignación de caso',	'Aceptación de caso',	'Rechazo de caso',
                              'Inicio del rescate',	'Llegada al rescate',	'Reporte de ficha',	'Derivación a centro',
                              'Llegada a centro',	'Finalización del caso',	'Categorización secundaria',
                              'Detalles categorización secundaria',	'Observaciones categorización secundaria',	'Centro de derivación']
        console.log(value[0], page_1_names)
        return this.compare_elements(value[0], page_1_names, 'external')              
      })
    })

    if(document.getElementById('error-external').classList.contains('d-none')){
      document.getElementById('error-external').classList.add('d-none')
      document.getElementById('check-external').classList.remove('d-none')
      document.getElementById('submit_btn-external').classList.remove('disabled')
    }
    // if (!this.compare_elements(rows[0], page_1_names)){alert('El archivo no coincide con el formato') }

    this.element.classList.remove('verifying')
  }

  processRowsCenter(file) {
    document.getElementById('error-movement').classList.add('d-none')
    document.getElementById('check-movement').classList.add('d-none')
    this.element.classList.add('verifying')
    readSheetNames(file).then((sheetNames) => {
      readXlsxFile(file).then((value) => {
        const page_1_names = ['Dirección*', 'Región (código)*', 'Comuna', 'Tipo(sim/res/Eva)*',
                              'Nombre del paciente*', 'Apellido del paciente*', 'Rut paciente',
                              'Edad paciente', 'Sexo', 'Categoría', 'Detalles de categoría', 'Observaciones categoría',
                              'Categoría secundaria', 'Detalles de categoría secundaria', 'Observaciones categoría secundaria',
                              'Razón social', 'Rut empresa', 'Teléfono de contacto', 'Antecedentes mórbidos', 'Origen del accidente',
                              'Mecanismo del accidente', 'Parte lesionada', 'Condición de la lesión', 
                              'Procedimiento', 'AVDI', 'Frecuencia cardíaca', 'Frecuencia respiratoria',
                              'Saturación de oxígeno', 'Temperatura', 'Dolor EVA', 'Hemoglucotest',
                              'Presión SIS', 'Presión DIA', 'Observaciones', 'Centro de derivación', 'Vehículo (código)',
                              'Correo conductor', 'Servicio clínico', 'Nombre de quien recibió al paciente', 'Apellido de quien recibió al paciente', 
                              'Cargo de quien recibió al paciente', 'Creación de caso', 'Asignación de caso',
                              'Aceptación de caso', 'Rechazo de caso', 'Inicio del rescate', 'Llegada al rescate',
                              'Reporte de ficha', 'Derivación a centro', 'Llegada a centro', 'Finalización del caso']
        console.log(value[0], page_1_names)
        return this.compare_elements(value[0], page_1_names, 'movement')              
      })
    })

    if(document.getElementById('error-movement').classList.contains('d-none')){
      document.getElementById('error-movement').classList.add('d-none')
      document.getElementById('check-movement').classList.remove('d-none')
      document.getElementById('submit_btn-movement').classList.remove('disabled')
    }
    // if (!this.compare_elements(rows[0], page_1_names)){alert('El archivo no coincide con el formato') }

    this.element.classList.remove('verifying')
  }

  compare_elements(file, objective, entity){
    var result = file.length === objective.length && 
    file.every((value, index) => value === objective[index])
    console.log("resultado", result)
    if(!result){
      this.set_errors(entity)
    }
    return result
  }
  compare_mixed_elements(file, objective){
    const sortedFile = file.slice().sort();
    const sortedObjective = objective.slice().sort();
    const result =
      sortedFile.length === sortedObjective.length &&
      sortedFile.every((value, index) => value === sortedObjective[index]);
    console.log("resultado", result);
    if (!result) {
      this.set_errors();
    }
    return result;
  }
  normalize(str) {
    if (str === null || str === undefined) {
      return;
    }
  
    return str
      .trim()
      .replace(/ /g, '-')
      .toLowerCase()
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u');
  }
  set_errors(entity){
    document.getElementById('check-' + entity).classList.add('d-none')
    document.getElementById('error-' + entity).classList.remove('d-none')
    document.getElementById('submit_btn-' + entity).classList.add('disabled')
  }
}