import consumer from "./consumer"
import L from 'leaflet';


consumer.subscriptions.create("VehicleMonitorChannel", {
  connected() {
    console.log("conectado al canal de posicion de vehiculo particular")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
		console.log('data trae', data)
		if(window.location.pathname == `/vehicles/${data.vehicle_id}/monitor`){
			this.updateVehiclePosition(data);
		}
    // Called when there's incoming data on the websocket for this channel
    
  },

  updateVehiclePosition(data){
		console.log(data)
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-van-shuttle" id="vehicleMarker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    var newLatLng = new L.LatLng(data.latitude, data.longitude);
    document.getElementsByClassName('alert-danger')[0].classList.add('d-none')
    document.getElementsByClassName('alert-primary')[0].classList.remove('d-none')
    window[`vehicleMarker${data.vehicle_id}`].setIcon(generatedIcon)
		window[`vehicleMarker${data.vehicle_id}`].setLatLng(newLatLng);
    window[`monitorMap${data.vehicle_id}`].setView([data.latitude, data.longitude], 15)
  }
});
