import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    addressId: String,
    latitudeId: String,
    longitudeId: String,
  }
  
  findLocationByLatLong() {
    if (typeof google === "undefined" || typeof google.maps === "undefined") {
      window.addEventListener('googleMapsLoaded', () => {
        this.executeFindLocationByLatLong();
      });
    } else {
      this.executeFindLocationByLatLong();
    }
  }
  
  executeFindLocationByLatLong() {
    let latitudeInput = document.getElementById(this.latitudeIdValue);
    let longitudeInput = document.getElementById(this.longitudeIdValue);
    if (!this.isValidateLatLong(latitudeInput.value, longitudeInput.value)) {
      console.error('Invalid Latitude or Longitude');
      return; 
    }
    let latLng = {"lat": parseFloat(latitudeInput.value),"lng": parseFloat(longitudeInput.value)}
    let geocoder = new google.maps.Geocoder();
    let self = this;
    geocoder.geocode({'location': latLng}, function(results, status) {
      if (status === 'OK') {
        if (results.length == 0) return;
        let place = results[0];

        if (!place.geometry) {
          console.log("<%=t('shared.errors.reference_point')%>");
          return;
        }
        self.updateAddressFields(place);

      } else {
        console.log("geoCoderError"+' '+ status);
      }
    });

  }

  updateAddressFields(place) {
    let addressInput = document.getElementById(this.addressIdValue);
    if (addressInput != null){
      addressInput.value = place.formatted_address
    }
    const placeChangedEvent = new CustomEvent("changed_location_map", { 
      detail: { place: place }
    });
    this.element.dispatchEvent(placeChangedEvent);
  }
 
  isValidateLatLong(lat, long) {
    let validLat = lat && isFinite(lat) && lat >= -90 && lat <= 90;
    let validLong = long && isFinite(long) && long >= -180 && long <= 180;
    return validLat && validLong;
  }
  
}