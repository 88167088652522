import { Controller } from "@hotwired/stimulus";
import 'leaflet-control-geocoder';
import L from 'leaflet';

export default class extends Controller {
  static targets = ["setDataMap"];

  map = null;
  markers = [];
  geocoder = null

  connect() {
		this.setDefaultMap();
  }

	setDefaultMap(){
		this.map = new L.Map("mapGoogle",{
      minZoom: 1,
    });
    let tileLayer;
    if (this.setDataMapTarget.dataset.monitorTypeValue === "google") {
      tileLayer = this.setGoogleMapTileLayer();
    } else if (this.setDataMapTarget.dataset.monitorTypeValue === "here") {
      tileLayer = this.setHereMapTileLayer();
    }
    this.map.setView(new L.LatLng('-33.4489', '-70.6693'), 10);
    tileLayer.addTo(this.map);
	}

  setMarker() {
    let vehicle = this.setDataMapTarget.dataset.monitorVehicleValue
    document.getElementById('monitor-map').innerHTML = `<div style='height: 100%; max-height: 100vh;' id='mapGoogle${vehicle}'></div>`;
    this.adjustColorToDiv();
    this.initGeocoderProvider();
    this.initializeMap();
  }

  initGeocoderProvider() {
    if (this.setDataMapTarget.dataset.monitorTypeValue === "google") {
      this.geocoder = L.Control.Geocoder.google({
        apiKey: this.setDataMapTarget.dataset.monitorApiKeyValue,
      });
    } else if (this.setDataMapTarget.dataset.monitorTypeValue === "here") {
      this.geocoder = L.Control.Geocoder.here({
        apiKey: this.setDataMapTarget.dataset.monitorApiKeyValue,
        maxResults: 2,
        reverseGeocodeProxRadius: 2
      });
    }
  }

  setHereMapTileLayer() {
    let tileLayerUrl = this.setDataMapTarget.dataset.monitorUrlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      attribution: '<a href="https://www.here.com/">Here</a>',
    })
    return tileLayer;
  }

  setGoogleMapTileLayer() {
    let tileLayerUrl = this.setDataMapTarget.dataset.monitorUrlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '<a href="https://www.google.com/maps">Google</a>',
    })
    return tileLayer;
  }

  generatedIcon(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-location-dot monitor-center-icon"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

  generatedIcon2(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-house-medical monitor-center-icon"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

	generatedIcon3(){
		let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-van-shuttle" id="vehicleMarker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
	}

  generatedIcon4(lat){
    if(lat == ''){
      let generatedIcon = L.divIcon({
        className: 'lafleat-marker-purple',
        html: '<i class= "fa fa-ban"></i>',
        iconSize: [18.75, 24.94],
        iconAnchor: [10, 31]
      })
      return generatedIcon
    }
    else{
      let generatedIcon = L.divIcon({
        className: 'lafleat-marker-purple',
        html: '<i class= "fa fa-van-shuttle" id="vehicleMarker"></i>',
        iconSize: [18.75, 24.94],
        iconAnchor: [10, 31]
      })
      return generatedIcon
    }
  }

  addMarkerTo(icon,title,latLng,draggable) {
    let marker = L.marker(latLng, {
      title: title,
      draggable: draggable,
      icon: icon
    }).addTo(this.map);
    this.markerDragendEvent(marker);
    return marker;
  }

  markerDragendEvent(marker) {
    self = this;
    marker.on('dragend', function(event) {
      // Obtain latLong of map.event
      let latLng = event.target.getLatLng();
      self.geocoder.reverse(latLng, self.map.options.crs.scale(self.map.getZoom()), function(results) {
        if (results.length === 0) return;
        let place = results[0];
  
        if (!place.center) {
          console.log("Returned place contains no waypoint");
          return;
        }
        self.updateAddressFields(place);
      });
    });
  }

  initializeMap() {
    let latitude = this.setDataMapTarget.dataset.monitorLatitudeIdValue // -33.437853;
    let longitude = this.setDataMapTarget.dataset.monitorLongitudeIdValue // -70.650458;
    let vehicle = this.setDataMapTarget.dataset.monitorVehicleValue
    this.map = new L.Map(`mapGoogle${vehicle}`,{
      minZoom: 1,
    });
    window[`mapGoogle${vehicle}`] = this.map
    let tileLayer;
    if (this.setDataMapTarget.dataset.monitorTypeValue === "google") {
      tileLayer = this.setGoogleMapTileLayer();
    } else if (this.setDataMapTarget.dataset.monitorTypeValue === "here") {
      tileLayer = this.setHereMapTileLayer();
    }
    this.map.setView(new L.LatLng(latitude, longitude), 15);
    tileLayer.addTo(this.map);

    let marker = this.addMarkerTo(this.generatedIcon(), this.markerTitleValue, [latitude, longitude], false);
    window[`pointMarker${vehicle}`] = marker
    this.markers.push(marker);

		let center = this.setDataMapTarget.dataset.monitorCenterValue
		if(center != ''){
			var cLatitude = this.setDataMapTarget.dataset.monitorLatitudeCidValue // -33.437853;
			var cLongitude = this.setDataMapTarget.dataset.monitorLongitudeCidValue // -70.650458;
			var marker2 = this.addMarkerTo(this.generatedIcon2(), this.markerTitleValue, [cLatitude, cLongitude], false);
			this.markers.push(marker2);
		}

		if(vehicle != ''){
      console.log(this.setDataMapTarget.dataset.monitorCoordinatesVehicleValue)
      var vehicleCoordinates = JSON.parse(this.setDataMapTarget.dataset.monitorCoordinatesVehicleValue)
      var vIcon = this.generatedIcon4(vehicleCoordinates[`latitude_${vehicle}`])
			var vLatitude = vehicleCoordinates[`latitude_${vehicle}`] || '-33.445920' // -33.437853;
			var vLongitude = vehicleCoordinates[`longitude_${vehicle}`] || '-70.630531' // -70.650458;
			var marker3 = this.addMarkerTo(vIcon, this.markerTitleValue, [vLatitude, vLongitude], false);
			if(marker3 !== null){
				this.markers.push(marker3);
        window[`vehicleMarker${vehicle}`] = marker3
			}
		}
    
		if(this.setDataMapTarget.dataset.monitorStateValue == 'on_rescue_route' ||
       this.setDataMapTarget.dataset.monitorStateValue == 'on_rescue' ||
       this.setDataMapTarget.dataset.monitorStateValue == 'pending_derivation'||
       this.setDataMapTarget.dataset.monitorStateValue == 'assigned' ||
       this.setDataMapTarget.dataset.monitorStateValue == 'pre_assigned'){
			var group = new L.featureGroup([marker, marker3]);
			var polyline = L.polyline([[latitude, longitude],[vLatitude, vLongitude]], {
				color: '#A66FFF',
				weight: 3,
				opacity: 1,
				smoothFactor: 1
				}).addTo(this.map); 
        window[`polyline${vehicle}`] = polyline
		}
		else{
			var group = new L.featureGroup([marker, marker2]);
			var polyline = L.polyline([[vLatitude, vLongitude],[cLatitude, cLongitude]], {
				color: '#A66FFF',
				weight: 3,
				opacity: 1,
				smoothFactor: 1
				}).addTo(this.map); 
      window[`polyline${vehicle}`] = polyline
		}
		this.map.fitBounds(group.getBounds());

  }

  adjustColorToDiv() {
    for (var i = 0; i < this.element.parentElement.children.length; i++){
      var child = this.element.parentElement.children[i]
      child.style.cssText = 'background-color: none'
    }
    this.element.style.cssText = 'background-color: #F4EEFF'
  }
}
