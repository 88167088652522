import { Controller } from "@hotwired/stimulus";
import DataTable from "datatables.net-dt"
export default class extends Controller {

  connect() {
    new DataTable('#vehicleTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: 7 },
      ]
    })
    new DataTable('#centerTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: 5 },
      ]
    })
    new DataTable('#externalTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: 4 },
      ]
    })
    new DataTable('#userTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: 6 },
      ]
    })
    new DataTable('#assignationTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: [0, 8] },
      ]
    })
  }
  
}