import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus";
import { validateRut } from 'rutlib';

export default class extends Controller {
  static targets= [
    'validateRutPatient'
  ]
  connect() {
    new MultiSelectTag('accidentMechanism')
    new MultiSelectTag('injuredPart')
    new MultiSelectTag('injuryCondition')
    new MultiSelectTag('morbidBackground')
    flatpickr(".date_and_hour", {
      dateFormat: "d-m-Y H:i",
      enableTime: true,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],         
        }, 
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        },
      },
    })
    console.log('conecto', this)
  }
  setValue() {
    let accidentMechanism = []
    let mechanismOptions = document.getElementById('accidentMechanism').selectedOptions
    for (const [key, value] of Object.entries(mechanismOptions)){
      accidentMechanism.push(value.value)
    }
    document.getElementById('accidentMechanismValue').value = accidentMechanism

    let injuredPart = []
    let injuredPartOptions = document.getElementById('injuredPart').selectedOptions
    for (const [key, value] of Object.entries(injuredPartOptions)){
      injuredPart.push(value.value)
    }
    document.getElementById('injuredPartValue').value = injuredPart

    let injuryCondition = []
    let injuryConditionOptions = document.getElementById('injuryCondition').selectedOptions
    for (const [key, value] of Object.entries(injuryConditionOptions)){
      injuryCondition.push(value.value)
    }
    document.getElementById('injuryConditionValue').value = injuryCondition

    let morbidBackground = []
    let morbidBackgroundOptions = document.getElementById('morbidBackground').selectedOptions
    for (const [key, value] of Object.entries(morbidBackgroundOptions)){
      morbidBackground.push(value.value)
    }
    document.getElementById('morbidBackgroundValue').value = morbidBackground
  }
  validateRutPatient() {
    let patientRut = document.getElementById('patient_rut').value
    if (validateRut(patientRut)){
      document.getElementById('alert_patient_rut').classList.add('d-none')
    }
    else {
      document.getElementById('alert_patient_rut').classList.remove('d-none')
    }
  }
  validateRutEnterprise() {
    let enterpriseRut = document.getElementById('enterprise_rut').value
    if (validateRut(enterpriseRut)){
      document.getElementById('alert_enterprise_rut').classList.add('d-none')
    }
    else {
      document.getElementById('alert_enterprise_rut').classList.remove('d-none')
    }
  }
  verifyHeartRate(){
    let value = document.getElementById('heart_rate').value
    if (value < 60){
      document.getElementById('alert-heart-rate-high').classList.add('d-none')
      document.getElementById('alert-heart-rate-low').classList.remove('d-none')
    }
    if (value > 100){
      document.getElementById('alert-heart-rate-high').classList.remove('d-none')
      document.getElementById('alert-heart-rate-low').classList.add('d-none')
    }
    if (value <= 100 && value >= 60){
      document.getElementById('alert-heart-rate-high').classList.add('d-none')
      document.getElementById('alert-heart-rate-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-heart-rate-high').classList.add('d-none')
      document.getElementById('alert-heart-rate').classList.remove('d-none')
      document.getElementById('alert-heart-rate-low').classList.add('d-none')
    }
    else{
      document.getElementById('alert-heart-rate').classList.add('d-none')
    }
  }
  verifyRespiratoryRate(){
    let value = document.getElementById('respiratory_rate').value
    if (value < 12){
      document.getElementById('alert-respiratory-rate-high').classList.add('d-none')
      document.getElementById('alert-respiratory-rate-low').classList.remove('d-none')
    }
    if (value > 20){
      document.getElementById('alert-respiratory-rate-high').classList.remove('d-none')
      document.getElementById('alert-respiratory-rate-low').classList.add('d-none')
    }
    if (value <= 20 && value >= 12){
      document.getElementById('alert-respiratory-rate-high').classList.add('d-none')
      document.getElementById('alert-respiratory-rate-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-respiratory-rate').classList.remove('d-none')
      document.getElementById('alert-respiratory-rate-high').classList.add('d-none')
      document.getElementById('alert-respiratory-rate-low').classList.add('d-none')
    }
    else{
      document.getElementById('alert-respiratory-rate').classList.add('d-none')
    }
  }
  verifyOxigenSaturation(){
    let value = document.getElementById('oxigen_saturation').value
    if (value < 95){
      document.getElementById('alert-oxigen-saturation-low').classList.remove('d-none')
      document.getElementById('alert-oxigen-saturation-high').classList.add('d-none')
    }
    if (value > 100){
      document.getElementById('alert-oxigen-saturation-low').classList.add('d-none')
      document.getElementById('alert-oxigen-saturation-high').classList.remove('d-none')
    }
    if (value >= 95 && value <=100){
      document.getElementById('alert-oxigen-saturation-low').classList.add('d-none')
      document.getElementById('alert-oxigen-saturation-high').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-oxigen-saturation').classList.remove('d-none')
      document.getElementById('alert-oxigen-saturation-low').classList.add('d-none')
      document.getElementById('alert-oxigen-saturation-high').classList.add('d-none')
    }
    else{
      document.getElementById('alert-oxigen-saturation').classList.add('d-none')
    }
  }
  verifyTemperature(){
    let value = document.getElementById('temperature').value
    if (value < 36){
      document.getElementById('alert-temperature-high').classList.add('d-none')
      document.getElementById('alert-temperature-low').classList.remove('d-none')
    }
    if (value > 37.4){
      document.getElementById('alert-temperature-high').classList.remove('d-none')
      document.getElementById('alert-temperature-low').classList.add('d-none')
    }
    if (value >= 36 && value <= 37.4){
      document.getElementById('alert-temperature-high').classList.add('d-none')
      document.getElementById('alert-temperature-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-temperature-high').classList.add('d-none')
      document.getElementById('alert-temperature-low').classList.add('d-none')
      document.getElementById('alert-temperature').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-temperature').classList.add('d-none')
    }
  }
  verifyEvaPain(){
    let value = document.getElementById('eva_pain').value
    console.log(value)
    if (value > 5){
      document.getElementById('alert-eva-pain-high').classList.remove('d-none')
      document.getElementById('alert-eva-pain-low').classList.add('d-none')
    }
    if (value >= 1 && value <= 5){
      document.getElementById('alert-eva-pain-high').classList.add('d-none')
      document.getElementById('alert-eva-pain-low').classList.add('d-none')
    }
    if ((value < 1 || value > 10) && value != ''){
      document.getElementById('alert-eva-pain-high').classList.add('d-none')
      document.getElementById('alert-eva-pain-low').classList.add('d-none')
      document.getElementById('alert-eva-pain').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-eva-pain').classList.add('d-none')
    }
  }
  verifyHemoglucotest(){
    let value = document.getElementById('hemoglucotest').value
    if (value < 70){
      document.getElementById('alert-hemoglucotest-high').classList.add('d-none')
      document.getElementById('alert-hemoglucotest-low').classList.remove('d-none')
    }
    if (value > 140){
      document.getElementById('alert-hemoglucotest-high').classList.remove('d-none')
      document.getElementById('alert-hemoglucotest-low').classList.add('d-none')
    }
    if (value >= 70 && value <= 140){
      document.getElementById('alert-hemoglucotest-high').classList.add('d-none')
      document.getElementById('alert-hemoglucotest-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-hemoglucotest-high').classList.add('d-none')
      document.getElementById('alert-hemoglucotest-low').classList.add('d-none')
      document.getElementById('alert-hemoglucotest').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-hemoglucotest').classList.add('d-none')
    }
  }
  verifyAvdi(){
    let value = document.getElementById('avdi').value
    if (value != '' && value != 'Alerta'){
      document.getElementById('alert-avdi').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-avdi').classList.add('d-none')
    }
    console.log('entro al avdi', value)
  }
  verifyPressureSis(){
    let value = document.getElementById('pressure_sis').value
    if (value < 90){
      document.getElementById('alert-pressure-sis-high').classList.add('d-none')
      document.getElementById('alert-pressure-sis-low').classList.remove('d-none')
    }
    if (value > 140){
      document.getElementById('alert-pressure-sis-high').classList.remove('d-none')
      document.getElementById('alert-pressure-sis-low').classList.add('d-none')
    }
    if (value >= 90 && value <= 140){
      document.getElementById('alert-pressure-sis-high').classList.add('d-none')
      document.getElementById('alert-pressure-sis-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-pressure-sis-high').classList.add('d-none')
      document.getElementById('alert-pressure-sis-low').classList.add('d-none')
      document.getElementById('alert-pressure-sis').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-pressure-sis').classList.add('d-none')
    }
  }
  verifyPressureDia(){
    let value = document.getElementById('pressure_dia').value
    if (value < 60){
      document.getElementById('alert-pressure-dia-high').classList.add('d-none')
      document.getElementById('alert-pressure-dia-low').classList.remove('d-none')
    }
    if (value > 90){
      document.getElementById('alert-pressure-dia-high').classList.remove('d-none')
      document.getElementById('alert-pressure-dia-low').classList.add('d-none')
    }
    if (value >= 60 && value <= 90){
      document.getElementById('alert-pressure-dia-high').classList.add('d-none')
      document.getElementById('alert-pressure-dia-low').classList.add('d-none')
    }
    if (value < 0){
      document.getElementById('alert-pressure-dia-high').classList.add('d-none')
      document.getElementById('alert-pressure-dia-low').classList.add('d-none')
      document.getElementById('alert-pressure-dia').classList.remove('d-none')
    }
    else{
      document.getElementById('alert-pressure-dia').classList.add('d-none')
    }
  }
}