import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [
    'user_id'
  ]
  connect() {
    new MultiSelectTag('regionIds'+this.user_idTarget.value)
  }

  setValue() {
    let regionIds = []
    let regionIdsOptions = document.getElementById('regionIds'+this.user_idTarget.value).selectedOptions
    console.log(regionIdsOptions)
    for (const [key, value] of Object.entries(regionIdsOptions)){
      regionIds.push(value.value)
    }
    document.getElementById('regionIdsValue'+this.user_idTarget.value).value = regionIds
  }

  verifyPassword() {
    console.log("entra a verificar contraseña", this.user_idTarget.value)
    this.password = document.querySelector('#edit_password_field'+this.user_idTarget.value)
    this.password_confirmation = document.querySelector('#edit_password_confirmation'+this.user_idTarget.value)
    this.lengthpswd = document.querySelector('#length'+this.user_idTarget.value)
    this.capitalpswd = document.querySelector('#capital'+this.user_idTarget.value)
    this.numberpswd = document.querySelector('#number'+this.user_idTarget.value)
    this.confirmationpswd = document.querySelector('#confirmation'+this.user_idTarget.value)
    this.sendBtn = document.querySelector('#send_btn'+this.user_idTarget.value)
    this.validatePassword(this.password, this.password_confirmation, this.lengthpswd, this.capitalpswd, this.numberpswd, this.sendBtn, this.confirmationpswd)
  }

  validatePassword(password, password_confirmation, lengthpswd, capitalpswd, numberpswd, sendBtn, confirmationpswd) {
    if (password.value.length < 8 || password.value.length > 20) {
      lengthpswd.style.color = "red";
    } else {
      lengthpswd.style.color = "green";
    }

    if (!password.value.match(/[A-Z]/)) {
      capitalpswd.style.color = "red";
    } else {
      capitalpswd.style.color = "green";
    }

    if (!password.value.match(/\d/)) {
      numberpswd.style.color = "red";
    } else {
      numberpswd.style.color = "green";
    }
    if((password.value === password_confirmation.value) && password.value.length > 0 ){
      confirmationpswd.style.color = "green";
    }else{
      confirmationpswd.style.color = "red";
    }
    if (password.value.length < 8 ||
      password.value.length > 20 ||
      !password.value.match(/[A-Z]/) ||
      !password.value.match(/\d/) ||
      !(password.value === password_confirmation.value)) {
      sendBtn.disabled = true
    } else {
      sendBtn.disabled = false
    }
  }
}
