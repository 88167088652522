import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    'entity_id'
  ]

  connect() {
    console.log('conecto con el external', this)
    new MultiSelectTag('vehiclesKind'+this.entity_idTarget.value)
    this.initializeVehiclesKind();
  }
  setValue() {
    this.initializeVehiclesKind();
  }

  initializeVehiclesKind() {
    let vehiclesKind = []
    let vehiclesKindOptions = document.getElementById('vehiclesKind'+this.entity_idTarget.value).selectedOptions
    for (const [key, value] of Object.entries(vehiclesKindOptions)){
      vehiclesKind.push(value.value)
    }
    document.getElementById('vehiclesKindValue'+this.entity_idTarget.value).value = vehiclesKind
  }
}