import { Controller } from "@hotwired/stimulus";
import L from 'leaflet';
import 'leaflet-control-geocoder';

export default class extends Controller {
  static values = {
    url: String,
    type: String,
    addressId: String,
    latitudeId: String,
    longitudeId: String,
    markerTitle: String,
    apiKey: String,
  }

  map = null;
  markers = [];
  geocoder = null

  connect() {
    this.initGeocoderProvider();
    this.initializeMap();
  }

  generatedIcon(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-map-marker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

  addMarkerTo(icon,title,latLng,draggable) {
    let marker = L.marker(latLng, {
      title: title,
      draggable: draggable,
      icon: icon
    }).addTo(this.map);
    this.markerDragendEvent(marker);
    return marker;
  }
  initGeocoderProvider() {
    if (this.typeValue === "google") {
      this.geocoder = L.Control.Geocoder.google({
        apiKey: this.apiKeyValue, 
      });
    } else if (this.typeValue === "here") {
      this.geocoder = L.Control.Geocoder.here({
        apiKey: this.apiKeyValue, 
        maxResults: 2,
        reverseGeocodeProxRadius: 2
      });
    }
  }

  setHereMapTileLayer() {
    let tileLayerUrl = this.urlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      attribution: '<a href="https://www.here.com/">Here</a>',
    })
    return tileLayer;
  }

  setGoogleMapTileLayer() {
    let tileLayerUrl = this.urlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '<a href="https://www.google.com/maps">Google</a>',
    })
    return tileLayer;
  }

  markerDragendEvent(marker) {
    self = this;
    marker.on('dragend', function(event) {
      // Obtain latLong of map.event
      let latLng = event.target.getLatLng();
      self.geocoder.reverse(latLng, self.map.options.crs.scale(self.map.getZoom()), function(results) {
        if (results.length === 0) return;
        let place = results[0];
  
        if (!place.center) {
          console.log("Returned place contains no waypoint");
          return;
        }
        
        self.updateAddressFields(place);
      });
    });
  }

  initializeMap() {
    let latitude = this.latitudeIdValue // -33.437853;
    let longitude = this.longitudeIdValue // -70.650458;
    
    this.map = new L.Map(this.element,{
      minZoom: 1,
    });
    let tileLayer;
    if (this.typeValue === "google") {
      tileLayer = this.setGoogleMapTileLayer();
    } else if (this.typeValue === "here") {
      tileLayer = this.setHereMapTileLayer();
    }
    this.map.setView(new L.LatLng(latitude, longitude), 15);
    tileLayer.addTo(this.map);

    let marker = this.addMarkerTo(this.generatedIcon(), this.markerTitleValue, [latitude, longitude], false);
    this.markers.push(marker);
  }
}
