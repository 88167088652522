import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('Connected Read Unread')
  }
  read() {
    console.log('Read Function')
  }
  unread(){
    console.log('Unread Function')
  }
}
