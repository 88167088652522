import { Controller } from "@hotwired/stimulus";
import { validateRut } from 'rutlib';

export default class extends Controller {
  static targets = [
    'entity_id'
  ]

  connect() {
    new MultiSelectTag('regionsIds'+this.entity_idTarget.value)
    console.log('conecto', this)
    this.initializeRegionsIds();
  }
  setValue() {
    this.initializeRegionsIds();
  }

  initializeRegionsIds() {
    let regionsIds = []
    let regionsIdsOptions = document.getElementById('regionsIds'+this.entity_idTarget.value).selectedOptions
    for (const [key, value] of Object.entries(regionsIdsOptions)){
      regionsIds.push(value.value)
    }
    document.getElementById('regionsIdsValue'+this.entity_idTarget.value).value = regionsIds
  }
}