import { Controller } from "@hotwired/stimulus";
import DataTable from "datatables.net-dt"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  connect() {
    new DataTable('#indexTable', {
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: [0, 10] }
      ]
    })
    new DataTable('#historyTable', {
      autoWidth: false,
      language: {
        search: "Buscar",
        entries: "por página",
        lengthMenu: "_MENU_ Filas por página",
        emptyTable: "Sin datos por el momento"
      },
      columnDefs: [
        { orderable: false, targets: [0, 10] },
      ]
    })
    this.showErrorModal()
  }

  showErrorModal() {
    let errors = document.getElementById('errorModal')
    if(errors){
      let modal = new bootstrap.Modal(errors)
       modal.show()
    }
  }
}
