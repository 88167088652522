import { Controller } from "@hotwired/stimulus";
import 'leaflet-control-geocoder';
import L from 'leaflet';

export default class extends Controller {
  static targets = ["setDataMap"];

  map = null;
  markers = [];
  geocoder = null

  connect() {
  }

  setMarker() {
    document.getElementById('center-suggestion-map').innerHTML = "<div style='height: 100%; max-height: 100vh;' id='mapGoogle'></div>";
    this.adjustColorToDiv();
    this.initGeocoderProvider();
    this.initializeMap();
  }

  initGeocoderProvider() {
    if (this.setDataMapTarget.dataset.centerTypeValue === "google") {
      this.geocoder = L.Control.Geocoder.google({
        apiKey: this.setDataMapTarget.dataset.centerApiKeyValue,
      });
    } else if (this.setDataMapTarget.dataset.centerTypeValue === "here") {
      this.geocoder = L.Control.Geocoder.here({
        apiKey: this.setDataMapTarget.dataset.centerApiKeyValue,
        maxResults: 2,
        reverseGeocodeProxRadius: 2
      });
    }
  }

  setHereMapTileLayer() {
    let tileLayerUrl = this.setDataMapTarget.dataset.centerUrlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      attribution: '<a href="https://www.here.com/">Here</a>',
    })
    return tileLayer;
  }

  setGoogleMapTileLayer() {
    let tileLayerUrl = this.setDataMapTarget.dataset.centerUrlValue;
    let tileLayer = L.tileLayer(tileLayerUrl, {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '<a href="https://www.google.com/maps">Google</a>',
    })
    return tileLayer;
  }

  generatedIcon(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-map-marker"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

  generatedIcon2(){
    let generatedIcon = L.divIcon({
      className: 'lafleat-marker-purple',
      html: '<i class= "fa fa-house-medical"></i>',
      iconSize: [18.75, 24.94],
      iconAnchor: [10, 31]
    })
    return generatedIcon
  }

  addMarkerTo(icon,title,latLng,draggable) {
    let marker = L.marker(latLng, {
      title: title,
      draggable: draggable,
      icon: icon
    }).addTo(this.map);
    this.markerDragendEvent(marker);
    return marker;
  }

  markerDragendEvent(marker) {
    self = this;
    marker.on('dragend', function(event) {
      // Obtain latLong of map.event
      let latLng = event.target.getLatLng();
      self.geocoder.reverse(latLng, self.map.options.crs.scale(self.map.getZoom()), function(results) {
        if (results.length === 0) return;
        let place = results[0];
  
        if (!place.center) {
          console.log("Returned place contains no waypoint");
          return;
        }
        self.updateAddressFields(place);
      });
    });
  }

  initializeMap() {
    let latitude = this.setDataMapTarget.dataset.centerLatitudeIdValue // -33.437853;
    let longitude = this.setDataMapTarget.dataset.centerLongitudeIdValue // -70.650458;
    this.map = new L.Map("mapGoogle",{
      minZoom: 1,
    });
    let tileLayer;
    if (this.setDataMapTarget.dataset.centerTypeValue === "google") {
      tileLayer = this.setGoogleMapTileLayer();
    } else if (this.setDataMapTarget.dataset.centerTypeValue === "here") {
      tileLayer = this.setHereMapTileLayer();
    }
    this.map.setView(new L.LatLng(latitude, longitude), 15);
    tileLayer.addTo(this.map);

    let marker = this.addMarkerTo(this.generatedIcon(), this.markerTitleValue, [latitude, longitude], false);
    this.markers.push(marker);

    let vLatitude = this.setDataMapTarget.dataset.centerLatitudeVidValue // -33.437853;
    let vLongitude = this.setDataMapTarget.dataset.centerLongitudeVidValue // -70.650458;
    let marker2 = this.addMarkerTo(this.generatedIcon2(), this.markerTitleValue, [vLatitude, vLongitude], false);
    this.markers.push(marker2);

    var group = new L.featureGroup([marker, marker2]);

    this.map.fitBounds(group.getBounds());
  }

  adjustColorToDiv() {
    for (var i = 0; i < this.element.parentElement.children.length; i++){
      var child = this.element.parentElement.children[i]
      child.style.cssText = 'background-color: none'
    }
    this.element.style.cssText = 'background-color: #F4EEFF'
  }
}
