import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    console.log("conectado al canal de notifications")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    this.displayNotification(data);
  },

  displayNotification(data){
    let user_regions = regions.split(',')
    if (user_regions.includes('all') || user_regions.includes(data.region_id.toString()) ) {
      if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        console.log("notification granted");
        var notification = new Notification(data.title, { body: data.body });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function(permission){
          if (permission === "granted"){
            new Notification("Hi there")
          }
        });
      } else {
        console.warn("notification denied");
      }
    }
  }
});
